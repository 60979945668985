import React, { useState, useEffect } from 'react';
import { Col, Row, Container, Button, Spinner, Form } from 'react-bootstrap';
import '../c_styles/BuscadorSala.css';
import axios from 'axios';
import apiInstance from '../api/ApiInstance';
import Cookies from 'universal-cookie';

import HorarioBusSalas from '../components/HorarioBusSalas';

const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});

//const weekdays = ['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo'];
//const modulos = ["01-02","03-04","05-06","07-08","09-10","11-12","13-14","15-16","17-18"];
const conectado = new Cookies();
var id_usuario_actual = conectado.get('id');

function BuscadorSala() {
  //var list = [];
  const [nombrePeriodo, setPeriodo] = useState('');
  const [periodosAcademicos, setPeriodos] = useState([]);
  //const periodosAcademicos = ["2022-02","2022-01","2021-02","2021-01","2020-02","2020-01"];
  // variables de la informacion de la sala
  const [sala, setSala] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [codigo, setCodigo] = useState('');
  const [capacidad, setCapacidad] = useState('');
  const [responsable, setResponsable] = useState('');
  const [tipoSala, setTipoSala] = useState('');
  const [aforo, setAforo] = useState('');
  const [m2, setm2] = useState('');
  const [largo, setLargo] = useState('');
  const [largoConsulta, setLargoConsulta] = useState(0);
  const [ancho, setAncho] = useState('');
  const [findSala, setFindSala] = useState(null);
  const [findSalaFrom, setFindSalaFrom] = useState(null);
  const [findSalaTo, setFindSalaTo] = useState(null);
  const [hasta, setHasta] = useState('');

  // horario
  //const [listaModulos, setListaModulos] = useState([]);
  const [showHorario, setShowHorario] = useState(false);

  const toggleShowHorario = () => setShowHorario((p) => !p);

  // vistas de cargando
  const [isLoadingInfoSala, setIsLoadingInfoSala] = useState(false);

  useEffect(() => {
    //setPeriodo("2022-02");
    //BUSCAMOS EL PERIODO ACTIVO
    apiInstance.get(serverUrl + '/getPeriodoActivo').then((response) => {
      setPeriodo(response.data[0][0]);
    });

    //cambiar este get
    apiInstance
      .get(serverUrl + '/getAvisoById', { params: { id: 1 } })
      .then((response) => {})
      .catch((error) => {
        alert(error.response.data.message);
      });

    //BUSCAMOS LOS PERIODOS ACADEMICOS
    apiInstance.get(serverUrl + '/getPeriodos').then((response) => {
      //----console.log(response.data);
      setPeriodos(response.data);
    });
  }, []);

  // funcion para abrir el horario

  const abrirHorarioInfoSala = () => {
    //----console.log("estoy pinchando el modal");
    /* setTipoH(value[0]);
        setCodGrupoH(value[1]); */
    setShowHorario(true);
  };

  const seleccionarPeriodo = async (event) => {
    await apiInstance
      .get(serverUrl + '/getUnidades', { params: { id_usuario: id_usuario_actual } })
      .then((response) => {
        //setNombreAsig("");
        //setNombreSec("");
        setPeriodo(event.target.value);
        //setMiUnidades(response.data)
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  //funcion para buscar una sala
  const getInfoSala = async () => {
    setSala('');
    var codigoSala = findSala.toUpperCase();
    //codigoSala.toUpperCase();
    //----console.log(codigoSala);
    setFindSala('');
    setSala(codigoSala);
    //let a = codigoSala;
    //Number(a);
    ////----console.log(typeof(codigoSala));
    setIsLoadingInfoSala(true);
    setCodigo('');
    var soloNumero = codigoSala.split('', 9);
    var count = codigoSala[3] + codigoSala[4] + codigoSala[5];
    //----console.log(count)
    //----console.log(soloNumero)
    var largoConsulta = Number(count) - Number(count);
    setLargoConsulta(largoConsulta);

    //if(a > 0){
    //cambiar el segundo codigo de sala que corresponde al hasta
    ////----console.log("llama a horario en getInfosala")
    //getHorario(codigoSala, codigoSala);
    //----console.log("el tipo es: " + typeof(codigoSala));
    await apiInstance
      .get(serverUrl + '/getSalasByCodSalaBusc', { params: { periodo: nombrePeriodo, cod_sala: codigoSala, hasta: codigoSala } })
      .then((response) => {
        console.log(response.data);
        setDescripcion(response.data[0][1]);
        setCodigo(response.data[0][0]);
        setCapacidad(response.data[0][5]);
        setResponsable(response.data[0][20]);
        setTipoSala(response.data[0][21]);
        setAforo(response.data[0][6]);
        setm2((response.data[0][10] * 100) / 100);
        setLargo((response.data[0][8] * 100) / 100);
        setAncho((response.data[0][9] * 100) / 100);
        //este hasta sirve para la primera consulta
        setHasta(codigoSala);

        abrirHorarioInfoSala();

        setIsLoadingInfoSala(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //----console.log(error);
        setIsLoadingInfoSala(false);
      });
    //}
    /* else{
            alert("El codigo de sala ingresado no es valido");
            setIsLoadingInfoSala(false);
        } */
  };

  //  funcion para buscar salas por rango

  const getInfoSala2 = async () => {
    setCodigo('');
    setHasta('');
    setLargoConsulta(0);
    var codigoSala = findSalaFrom.toUpperCase();
    var codigoSalaHasta = findSalaTo.querySelector('.ingresarSalaHasta').value.toUpperCase();

    setFindSalaFrom('');
    setFindSalaTo('');
    setCodigo(codigoSala);
    setHasta(codigoSalaHasta);

    setIsLoadingInfoSala(true);

    var largoConsulta = Number(codigoSalaHasta) - Number(codigoSala);
    setLargoConsulta(largoConsulta);
    ////----console.log("largo a recorrer: " + largoConsulta);

    //getHorario(codigoSala, codigoSala);
    ////----console.log("el tipo es: " + typeof(codigoSala));
    await apiInstance
      .get(serverUrl + '/getHorarioBySalaBusc2', { params: { periodo: nombrePeriodo, cod_sala: codigoSala, hasta: codigoSalaHasta } })
      .then((response) => {
        console.log(response.data);
        /* setDescripcion(response.data[0][2]);
                setCodigo(response.data[0][0]);
                setCapacidad(response.data[0][1]);
                setResponsable(response.data[0][21]);
                setTipoSala(response.data[0][6]);
                setAforo(response.data[0][5]);
                setm2(response.data[0][17]);
                setLargo(response.data[0][3]);
                setAncho(response.data[0][4]);
                */
        abrirHorarioInfoSala();

        setIsLoadingInfoSala(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //----console.log(error);
        setIsLoadingInfoSala(false);
      });
  };

  /* const getHorario = async (codigoSala, hasta) => {  
        
        //----console.log("llame a horario con desde: " + codigoSala + " hasta: " + hasta);
        await apiInstance.get(serverUrl+"/getHorarioBySalaBusc", {params:{periodo: nombrePeriodo, cod_sala: codigoSala, hasta: hasta}})
        .then(response=>{
            ////----console.log("info horario: " + response.data);
            ////----console.log("info horario: en [0] " + response.data[0]);
            ////----console.log("LISTAMODULOS ANTES DE SET " + listaModulos)
            setListaModulos(response.data);
            //----console.log("IMPORTANTE data del getHorario: " + response.data);
            //----console.log("largo del arreglo: " + response.data.length);
            
            ////----console.log("LISTA MODULO DESP SET " + listaModulos);
            ////----console.log("LISTA MODULO DESP SET en [0]" + listaModulos[0]);
            
        })
        .catch(error=>{
            alert(error.response.data.message);
            setListaModulos([]);
            
            //----console.log(error);
        })  
    }; */

  /* for (let index = 0; index < modulos.length; index++) {
          list[index] = new Array(weekdays.length);
          for (let index2 = 1; index2 <= weekdays.length; index2++) {
            list[index][index2-1] = null;  
              
            let i = 0;
            listaModulos.forEach(element => {
                
                // elemt(3) es el dia, elemnt 4 es el modulo
            if(element[3] === index2 && element[4] === modulos[index] ){
                list[index][index2-1] = [element[0], element[5], element[6], element[7], i];
                /* //----console.log("INFO LIST" + list)   
                //----console.log("INFO LIST CON [] [] " + list[index][index2])        
                i++;    
            }  
            
          })      
        }
      }; */

  return (
    <div>
      <HorarioBusSalas
        show={showHorario}
        toggleShowHorario={toggleShowHorario}
        periodo={nombrePeriodo}
        sala={sala}
        //estos atributos son los del horario en la seccion superior
        descripcion={descripcion}
        codigo={codigo}
        capacidad={capacidad}
        responsable={responsable}
        tipoSala={tipoSala}
        aforo={aforo}
        m2={m2}
        largo={largo}
        ancho={ancho}
        // data de el horario

        codigoSala={codigo}
        hasta={hasta}
        nombrePeriodo={nombrePeriodo}
        //variable del largo de la consulta
        largoConsulta={largoConsulta}
      />

      <div className='contenedorNaranjo'>
        <div className='contenedorGris'>
          <div className='BuscadorSala'>
            <Container className='p-5'>
              <Row className='text-center'>
                <Col>
                  <h2>SALAS DE CLASES - OCUPACIÓN SEMESTRAL</h2>
                </Col>
              </Row>
              <Row className='mt-3'>
                <h5>Ingrese la Sala que desea revisar</h5>
              </Row>
              <Row>
                <hr style={{ background: '#002F82', color: '#002F82', borderColor: '# 002F82', height: '3px' }} />
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Row>
                      <Col sm={2}>
                        <Form.Label>Sala:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          placeholder='Ingrese la sala de desea buscar'
                          value={findSala}
                          onChange={(event) => {
                            setFindSala(event.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Row>
                      <Col sm={2}>
                        <Form.Label>Periodo:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Select
                          onChange={(event) => {
                            seleccionarPeriodo(event);
                          }}
                        >
                          {periodosAcademicos.map((periodo, index) => {
                            return (
                              <option value={periodo} key={periodosAcademicos[index]}>
                                {' '}
                                {periodo}{' '}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Button variant='primary' onClick={() => getInfoSala()}>
                    Revisar
                  </Button>
                </Col>
              </Row>
            </Container>

            {isLoadingInfoSala ? (
              <Col>
                <Row>
                  <Col className='loading2'>
                    <Button variant='primary' disabled>
                      <span>Cargando </span>
                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                    </Button>
                  </Col>
                </Row>
              </Col>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuscadorSala;
