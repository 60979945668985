import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import apiInstance from '../api/ApiInstance';
import Cookies from 'universal-cookie';
import '../c_styles/login.css';

const serverUrl = process.env.REACT_APP_SERVER;
const conectado = new Cookies();

const Login = () => {
  const [dataForm, setDataForm] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const iniciarSesion = async (e) => {
    e.preventDefault();
    await axios
      .post(serverUrl + '/doLogin', dataForm, {
        withCredentials: true,
      })
      .then((response) => {    
        conectado.set('id', response.data.user[0], { path: '/' });
        conectado.set('nombre', response.data.user[1], { path: '/' });
        window.location.replace('/');
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  return (
    <div id='login' style={{ width: '100%' }}>
      <Form onSubmit={iniciarSesion}>
        <h1>Iniciar sesión</h1>

        <Form.Group className='login-form mb-3' controlId='formBasicEmail'>
          <Form.Control type='username' placeholder='Correo usach' name='username' onChange={handleChange} />
        </Form.Group>

        <Form.Group className='login-form mb-3' controlId='formBasicPassword'>
          <Form.Control type='password' placeholder='Contraseña' name='password' onChange={handleChange} />
        </Form.Group>

        <Button type='submit' id='login_button' variant='primary'>
          Ingresar
        </Button>
      </Form>
    </div>
  );
};

export default Login;
