import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap';
import '../c_styles/UnidadAcademica.css';
import swal from 'sweetalert';
import axios from 'axios';
import apiInstance from '../api/ApiInstance';
const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});

function ModalModificarRol(props) {
  const [nuevoNumeroSector, setNuevoNumeroSector] = useState('');
  const [nuevoNombreSector, setNuevoNombreSector] = useState('');
  const [nuevoDescripcionSector, setNuevoDescripcionSector] = useState('');

  useEffect(() => {
    setNuevoNombreSector(props.sector[1]);
    setNuevoNumeroSector(props.sector[0]);
    setNuevoDescripcionSector(props.sector[2]);
  }, [props]);

  const modificarSector = async (props) => {
    await ax({
      method: 'put',
      url: serverUrl + '/updateSector',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        id: props.sector[0],
        nombre: nuevoNombreSector,
        descripcion: nuevoDescripcionSector,
      },
    })
      .then((response) => {
        //----console.log(response.data);
        window.location.reload(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const confirmarModificarSector = (props) => {
    swal({
      title: 'Modificar sector',
      text: '¿Está seguro que desea modificar este sector?',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
    }).then((respuesta) => {
      if (respuesta) {
        swal({ text: 'El sector ha sido modificado exitosamente!', icon: 'success', timer: '4000' });
        modificarSector(props);
      }
    });
  };

  return (
    <Modal {...props} centered>
      <Modal.Header>
        <Modal.Title>Modificar Sector</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <Form.Group className='mb-3'>
          <Form.Label>Número sector</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoNumeroSector(event.target.value);
            }}
            defaultValue={props.sector[0]}
            type='text'
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Nombre del Sector</Form.Label>
          <Form.Control
            onChange={(event) => {
              nuevoNombreSector(event.target.value);
            }}
            defaultValue={props.sector[1]}
            type='text'
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Descripción del Sector</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoDescripcionSector(event.target.value);
            }}
            defaultValue={props.sector[2]}
            type='text'
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            confirmarModificarSector(props);
          }}
        >
          Modificar Sector
        </Button>
        <Button variant='outline-danger' onClick={props.onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalCrearSector(props) {
  const [nuevoNumeroSector, setNuevoNumeroSector] = useState('');
  const [nuevoNombreSector, setNuevoNombreSector] = useState('');
  const [nuevoDescripcionSector, setNuevoDescripcionSector] = useState('');

  const cerrarModalCrearSector = () => {
    setNuevoNombreSector('');
    props.onHide();
  };

  const crearSector = async () => {
    await ax({
      method: 'post',
      url: serverUrl + '/createSector',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        id: nuevoNumeroSector,
        nombre: nuevoNombreSector,
        descripcion: nuevoDescripcionSector,
      },
    })
      .then((response) => {
        //----console.log(response.data);
        window.location.reload(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const confirmarCrearSector = () => {
    swal({
      title: 'Crear Sector',
      text: '¿Está seguro que desea crear este sector?',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
    }).then((respuesta) => {
      if (respuesta) {
        swal({ text: 'El sector ha sido creado exitosamente!', icon: 'success', timer: '4000' });
        crearSector();
      }
    });
  };

  return (
    <Modal {...props} centered>
      <Modal.Header>
        <Modal.Title>Crear Nuevo Sector</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <Form.Group className='mb-3'>
          <Form.Label>Número de Sector</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoNumeroSector(event.target.value);
            }}
            type='text'
          />
          <Form.Label>Nombre del Sector</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoNombreSector(event.target.value);
            }}
            type='text'
          />
          <Form.Label>Descripción del Sector</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoDescripcionSector(event.target.value);
            }}
            type='text'
          />
        </Form.Group>
        <Form.Group className='mb-3'></Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={confirmarCrearSector}>Crear Sector</Button>
        <Button variant='outline-danger' onClick={cerrarModalCrearSector}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MantenedorSectores() {
  const [cargando1, setCargando1] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [modalCrearSector, setModalCrearSector] = useState(false);
  const [modalModificarSector, setModalModificarSector] = useState(false);
  const [funcionalidades, setFuncionalidades] = useState([]);
  const [sectores, setSectores] = useState([]);
  const [sectorActual, setSectorActual] = useState(['', '', []]);

  const getSectores = async () => {
    apiInstance
      .get(serverUrl + '/getSectores')
      .then((response) => {
        setSectores(response.data);
        setCargando2(true);
        setCargando1(true);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const modificarSector = (sector) => {
    setSectorActual(sector);
    //----console.log(sectorActual);
    setModalModificarSector(true);
  };

  const eliminarSector = async (sector) => {
    await apiInstance
      .delete(serverUrl + '/deleteSector', { params: { id: sector[0] } })
      .then((response) => {
        window.location.reload(false);
        //----console.log(response.data);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const confirmarEliminarSector = (sector) => {
    swal({
      title: 'Eliminar sector',
      text: '¿Está seguro que desea eliminar ese sector?',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
    }).then((respuesta) => {
      if (respuesta) {
        swal({ text: 'El sector ha sido eliminado exitosamente!', icon: 'success', timer: '5000' });
        eliminarSector(sector);
      }
    });
  };

  useEffect(() => {
    getSectores();
  }, []);

  if (cargando1 && cargando2) {
    return (
      <Container className='fondoUnidadAcademica' fluid>
        <Container className='UnidadAcademica' fluid>
          <Row className='mb-3'>
            <Col md={10}>
              <h3>Gestionar Sectores</h3>
            </Col>
            <Col md={2}>
              <Button variant='primary' onClick={() => setModalCrearSector(true)}>
                Crear nuevo sector
              </Button>
            </Col>
          </Row>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>Número Sector</th>
                <th>Nombre Sector</th>
                <th>Descripción Sector</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {sectores.map((sector) => {
                return (
                  <>
                    <tr key={sector.id}>
                      <td className='p-2'>{sector[0]}</td>
                      <td className='p-2'>{sector[1]}</td>
                      <td className='p-2'>{sector[2]}</td>
                      <td className='text-center p-2'>
                        <Button
                          size='sm'
                          variant='primary'
                          onClick={() => {
                            modificarSector(sector);
                          }}
                        >
                          Modificar
                        </Button>{' '}
                        <Button
                          size='sm'
                          variant='danger'
                          onClick={() => {
                            confirmarEliminarSector(sector);
                          }}
                        >
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          <ModalCrearSector show={modalCrearSector} onHide={() => setModalCrearSector(false)} />
          <ModalModificarRol sector={sectorActual} show={modalModificarSector} onHide={() => setModalModificarSector(false)} />
        </Container>
      </Container>
    );
  } else {
    return (
      <Container className='fondoUnidadAcademica' fluid>
        <Container className='UnidadAcademica' fluid>
          <Row>
            <Col></Col>
            <Col className='loading'>
              <Button variant='primary' disabled>
                <span>Cargando sectores... </span>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default MantenedorSectores;
