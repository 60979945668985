import React, { useEffect, useState } from 'react';
import { Col, Row, Container, Table, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import '../c_styles/InformeEstadistico.css';
import ListaSalasEstadistico from '../components/ListaSalasEstadistico';
import apiInstance from '../api/ApiInstance';
//import { read, write, utils } from 'xlsx';

const conectado = new Cookies();
var id_usuario_actual = conectado.get('id');

const weekdays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
const hora = [
  '08:00 - 09:30',
  '09:40 - 11:10',
  '11:20 - 12:50',
  '13:50 - 15:20',
  '15:30 - 17:00',
  '17:10 - 18:40',
  '18:45 - 20:10',
  '20:10 - 21:35',
  '21:35 - 23:00',
];

const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});

function InformeEstadistico() {
  const [listaEstadistico, setListaEstadistico] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [unidadFiltro, setUnidadFiltro] = useState('-1');
  const [unidadFiltroNombre, setUnidadFiltroNombre] = useState(null);
  //const [periodo, setPeriodo] = useState('2022-01');
  const [unidadesUsuario, setUnidadesUsuario] = useState([]);
  const [showlistarsalas, setShowListarSalas] = useState(false);
  const [modulos, setModulos] = useState([]);
  const [mod, setMod] = useState('');
  const [dia, setDia] = useState();
  const [periodo, setPeriodo] = useState('2022-02');
  const [periodosAcademicos, setPeriodos] = useState(['2022-01', '2021-02', '2021-01', '2020-02', '2020-01']);
  const [contador, setCountSala] = useState();

  const toggleShow = () => setShowListarSalas((p) => !p);

  useEffect(() => {
    apiInstance.get(serverUrl + '/getPeriodos').then((response) => {
      ////----console.log(response.data);
      setPeriodos(response.data);
    });

    apiInstance.get(serverUrl + '/getPeriodoActivo').then((response) => {
      setPeriodo(response.data[0][0]);
    });

    if (periodo !== '' && periodo !== '-+Seleccionar+-') {
      apiInstance
        .get(serverUrl + '/getModulosByPeriodo', { params: { periodo: periodo } })
        .then((res) => {
          ////----console.log(res.data);
          setModulos(res.data);
        })
        .catch((err) => alert(err));
    }

    apiInstance
      .get(serverUrl + '/getUnidades', { params: { id_usuario: id_usuario_actual } })
      .then((response) => {
        setUnidadesUsuario(response.data);
        ////----console.log("unidadesUsuario")
        ////----console.log(response.data)
      })
      .catch((error) => {
        alert(error.response.data.message);
      });

    apiInstance
      .get(serverUrl + '/getEstadisticoByIdUsuario', {
        params: { id_usuario: id_usuario_actual, periodo: periodo },
      })
      .then((response) => {
        setListaEstadistico(response.data);
      });
  }, []);

  async function downloadPDF() {
   try{
    const response = await apiInstance.get(serverUrl + '/getPdfEstadistico', {
      params: { id_usuario: id_usuario_actual, unidad: unidadFiltro, periodo: periodo },
      responseType: 'blob',
    });
    const file = new Blob([response.data],{type: 'application/pdf'});
    const url = URL.createObjectURL(file);
    window.open(url);
   } catch (error){
    console.error('Error al descargar PDF', error);
   }
   /*try{
    const response = await apiInstance.get(serverUrl + '/getPdfEstadistico', {
      params: { id_usuario: id_usuario_actual, unidad: unidadFiltro, periodo: periodo },
      responseType: 'arraybuffer',
    });
    const data = new Uint8Array(response.data);
    const workbook = read(data, {type: 'array'});
    const excelBuffer = write(workbook, {bookType: 'xlsx', type: 'array'});
    const blob = new Blob([excelBuffer],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
   } catch (error){
    console.error('Error al descargar Excel', error);
   }*/
  }

  function seleccionarUnidad(event) {
    setUnidadFiltro(event);
  }

  function abrirHorario(mod, i) {
    setMod(mod);
    setDia(i + 1);
    setShowListarSalas(true);
  }

  const seleccionarPeriodo = async (event) => {
    await apiInstance
      .get(serverUrl + '/getUnidades', { params: { id_usuario: id_usuario_actual } })
      .then((response) => {
        setPeriodo(event.target.value);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  useEffect(() => {
    if (unidadFiltro === '-1') {
      apiInstance
        .get(serverUrl + '/getEstadisticoByIdUsuario', {
          params: { id_usuario: id_usuario_actual, periodo: periodo },
        })
        .then((response) => {
          setListaEstadistico(response.data);
        });
      apiInstance
        .get(serverUrl + '/getAllSalasByIdUsuarioCount', {
          params: { id_usuario: id_usuario_actual },
        })
        .then((response) => {
          setCountSala(response.data);
        });
    } else {
      setPeriodo(periodo);
      setUnidadFiltro(unidadFiltro);
      apiInstance
        .get(serverUrl + '/getEstadisticoByUnidad', {
          params: { cod_unidad: unidadFiltro, periodo: periodo, id_usuario: id_usuario_actual },
        })
        .then((response) => {
          setListaEstadistico(response.data);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
      apiInstance
        .get(serverUrl + '/getAllSalasByIdUsuarioUnidadCount', {
          params: { id_usuario: id_usuario_actual, unidad: unidadFiltro },
        })
        .then((response) => {
          setCountSala(response.data);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  }, [unidadFiltro]);

  const buscarEstadistico = async () => {
    if (unidadFiltro === '-1') {
      apiInstance
        .get(serverUrl + '/getEstadisticoByIdUsuario', {
          params: { id_usuario: id_usuario_actual, periodo: periodo },
        })
        .then((response) => {
          setListaEstadistico(response.data);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      setPeriodo(periodo);
      apiInstance
        .get(serverUrl + '/getEstadisticoByUnidad', {
          params: { cod_unidad: unidadFiltro, periodo: periodo, id_usuario: id_usuario_actual },
        })
        .then((response) => {
          setListaEstadistico(response.data);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  return (
    <Container className='fondoUnidadAcademica' fluid>
      <ListaSalasEstadistico show={showlistarsalas} toggleShow={toggleShow} mod={mod} periodo={periodo} dia={dia} nombreDia={weekdays[dia - 1]} />
      <Container className='UnidadAcademica' fluid>
        <Row className='header-asg'>
          <Col sm={12} md={5} xl={2}>
            <h3>Informe Estadistico</h3>
          </Col>
          <Col sm={12} md={5} xl={3} style={{ textAlign: 'center' }}>
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <p>Periodo:</p>
              </Col>
              <Col>
                <Form.Select
                  aria-label='Default select example'
                  onChange={(event) => {
                    seleccionarPeriodo(event);
                  }}
                >
                  <option value={periodo}>{periodo}</option>
                  {periodosAcademicos.map((periodo, index) => {
                    return (
                      <option value={periodo} key={periodosAcademicos[index]}>
                        {' '}
                        {periodo}{' '}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4} xl={3} style={{ textAlign: 'center' }}>
            <Form.Select onChange={(event) => setUnidadFiltro(event.target.value)}>
              <option value={'-1'}>Todas las Unidades</option>
              {unidadesUsuario.map((facultades, index) => {
                return facultades[2].map((departamento, idx) => {
                  return (
                    <option value={departamento[1]} key={facultades[idx]}>
                      {' '}
                      {'[' + departamento[1] + ']' + ' ' + departamento[0]}{' '}
                    </option>
                  );
                });
              })}
            </Form.Select>
          </Col>
          <Col sm={2}>
            <Button onClick={buscarEstadistico}>Buscar</Button>
          </Col>
          <Col xl={2}>
            <Row>
              <Button onClick={downloadPDF}>DescargarPDF</Button>
            </Row>
          </Col>

          <Row>
            <strong>Periodo: {periodo}</strong>
          </Row>
          <Row sm={2}>
            <strong>Cantidad de salas: {contador}</strong>
          </Row>
        </Row>
        <Table responsive bordered id='fondo'>
          <thead>
            <tr id='dias'>
              <th> Módulo </th>
              {Array.from({ length: weekdays.length }).map((_, index) => (
                <th id='colDia' scope='col' key={weekdays[index]}>
                  {weekdays[index]}{' '}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {Array.from({ length: modulos.length }).map((_, index1) => (
              <tr key={index1}>
                <>
                  <th scope='row' key={modulos[index1]} className='cModulos'>
                    {modulos[index1][1]}
                    {'\n'}
                    {modulos[index1][2] + ' - ' + modulos[index1][3]}
                  </th>
                  {Array.from({ length: weekdays.length }).map((_, index2) => (
                    <td id='estadistico' key={modulos[index1] + weekdays[index2]}>
                      <a onClick={() => abrirHorario(modulos[index1][1], index2)}>
                        <span>
                          {listaEstadistico[index2 * 9 + (index1 % 9)] == null ? 0 + '%' : listaEstadistico[index2 * 9 + (index1 % 9)] + '%'}
                        </span>
                      </a>
                    </td>
                  ))}
                </>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </Container>
  );
}

export default InformeEstadistico;
