import axios from 'axios';
import Cookies from 'universal-cookie';

const BASE_URL = 'http://localhost:3200';
const apiInstance = axios.create({ baseURL: BASE_URL, withCredentials: true });
const conectado = new Cookies();

apiInstance.interceptors.request.use(
  (config) => {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    //config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    // console.warn(originalRequest);
    // if (error.response.status === 401) {
    //   // //----console.log(error);
    //   alert('2. token invalido');
    //   conectado.remove('id', { path: '/' });
    //   conectado.remove('nombre', { path: '/' });
    //   // window.location.replace('/');
    //   // return error.response.data;
    // }
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      // //----console.log('1. token invalido');
      conectado.remove('id', { path: '/' });
      conectado.remove('nombre', { path: '/' });
      alert('Sesión inválida');
      window.location.replace('/');
      // window.location.replace('/');
    }

    // //----console.log('3. token invalido');
    return Promise.reject(error);
  }
);

export default apiInstance;
