import React, { useEffect, useState } from 'react'
import '../c_styles/UnidadAcademica.css';
import swal from 'sweetalert';
import axios from "axios";
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'
import { Col, Row, ListGroup, Form, FormControl, Button, Container, Spinner, Table} from 'react-bootstrap';
const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});

function PrestamoDiario(){

  const [cargando1, setCargando1] = useState(true);

//Parametros iniciales para iniciar el modulo prestamo diario
useEffect(() => {



}, []);


  if (cargando1) {
    return (  
        <Container className="fondoUnidadAcademica" fluid>
            <Container className="UnidadAcademica" fluid>
                <Row className='mb-3'>
                    <Col md={3}><h3>Prestamo diario</h3></Col>
                    <Col md={2}><Button variant="primary" >Crear nuevo evento</Button></Col>
                </Row>
                <Row>
                  <FullCalendar

                  headerToolbar={{
                    start: "today prev next",
                    end: "dayGridMonth dayGridWeek dayGridDay"
                  }}

                  locale = {esLocale}
                  timeZone={'America/Chile'}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView='dayGridMonth'
                  selectable ={true}

                  eventSources={[
                    {
                      url: serverUrl+"/getEvento",
                      method: 'GET',
                    }
                  ]}
                  />
                </Row>
            </Container>
           
        </Container>
        
    );}
    else {
        return(
            <Container className="fondoUnidadAcademica" fluid>
            <Container className="UnidadAcademica" fluid>
                <Row>
                    <Col></Col>
                    <Col className="loading">
                        <Button variant="primary" disabled>
                            <span>Cargando eventos... </span> 
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                        </Button>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            </Container>
        );
    }
  
  }
  export default PrestamoDiario;