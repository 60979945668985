import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import '../c_styles/Horario.css';
import { Col, Row, Button, Container, Spinner, Modal } from 'react-bootstrap';
import '../c_styles/HorarioBusSalas.css';
import Cookies from 'universal-cookie';
import apiInstance from '../api/ApiInstance';

import MostrarVariasSecc from './MostrarVariasSecc';

import imagenUsach from '../assets/logoUsach.svg.png';

const conectado = new Cookies();
var id_usuario_actual = conectado.get('id');
const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});
const weekdays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
const modulos = ['01-02', '03-04', '05-06', '07-08', '09-10', '11-12', '13-14', '15-16', '17-18'];
//const hora = ["08:00 - 09:30","09:40 - 11:10","11:20 - 12:50","13:50 - 15:20","15:30 - 17:00","17:10 - 18:40","18:45 - 20:10","20:10 - 21:35","21:35 - 23:00"];
const hora = [
  '08:15 - 09:35',
  '09:50 - 11:10',
  '11:25 - 12:45',
  '13:45 - 15:05',
  '15:20 - 16:40',
  '16:55 - 18:15',
  '18:45 - 20:05',
  '20:05 - 21:25',
  '21:25 - 22:45',
];

const hora_antigua = [
  '08:00 - 09:30',
  '09:40 - 11:10',
  '11:20 - 12:50',
  '13:50 - 15:20',
  '15:30 - 17:00',
  '17:10 - 18:40',
  '18:45 - 20:10',
  '20:10 - 21:35',
  '21:35 - 23:00',
];

function HorarioBusSalas(props) {
  var list = [];
  var list2 = [[]];
  const [isLoading, setLoading] = useState(false);
  const [isLoadingVariasSecc, setLoadingVariasSecc] = useState(false);

  const [listaModulos, setListaModulos] = useState([]);
  const { show, toggleShowHorario } = props;
  const [dia, setDia] = useState('');
  const [numeroDia, setNumDia] = useState('');
  //const [largoConsulta, setLargoConsulta] = useState();
  const [modulo, setModulo] = useState('');
  //const [data, setData] = useState([]);
  const [file, setFile] = useState(null);

  //varias secciones

  const [showVariasSecc, setVariasSecc] = useState(false);

  const toggleShow = () => setVariasSecc((p) => !p);

  /* useEffect(() => {
    apiInstance.get(serverUrl+"/getUnidades", {params: {id_usuario: id_usuario_actual}})
    .then(response=>{
        //----console.log("largo de la consulta antes del set: " + props.largoConsulta)
        setLargoConsulta(props.largoConsulta)
        //----console.log("largo de la consulta: " + largoConsulta)
    })
    .catch(error=>{
        alert(error.response.data.message);
    });
  }, []); */

  /* const modulosGet = async (event) =>{
    await apiInstance.get(serverUrl+'/getHorariosByGrupo', {params:{id_grupo: props.grupo, tipo: props.tipo ,periodo: props.periodo, cod_asignatura: props.asignatura}})
      .then(response=>{
        setModulos(response.data);

        setLoading(true);
    })
    .catch (error=> {
      setModulos([]);
      alert(error.response.data.message);
      //----console.log(error);
    })
  }; */

  const getHorario = async (codigoSala) => {
    //----console.log("llame a horario1");
    //----console.log("llame a horario1 con un largo de consulta: " + props.largoConsulta);
    await apiInstance
      .get(serverUrl + '/getHorarioBySalaBusc', { params: { periodo: props.nombrePeriodo, cod_sala: codigoSala, hasta: props.hasta } })
      .then((response) => {
        ////----console.log("info horario: " + response.data);
        ////----console.log("info horario: en [0] " + response.data[0]);
        ////----console.log("LISTAMODULOS ANTES DE SET " + listaModulos)
        setListaModulos(response.data);
        setLoading(true);
        ////----console.log("LISTA MODULO DESP SET " + listaModulos);
        ////----console.log("LISTA MODULO DESP SET en [0]" + listaModulos[0]);
      })
      .catch((error) => {
        alert(error.response.data.message);
        setListaModulos([]);
        setLoading(true);
        //----console.log(error);
      });
  };

  const getHorario2 = async (codigoSala, codigoSalaHasta) => {
    //----console.log("llame a horario2");
    await apiInstance
      .get(serverUrl + '/getHorarioBySalaBusc2', { params: { periodo: props.nombrePeriodo, cod_sala: codigoSala, hasta: codigoSalaHasta } })
      .then((response) => {
        ////----console.log("info horario: " + response.data);
        ////----console.log("info horario: en [0] " + response.data[0]);
        ////----console.log("LISTAMODULOS ANTES DE SET " + listaModulos)
        setListaModulos(response.data);
        //----console.log("data de la consulta: " +response.data)
        //----console.log("data lista modulos: " +listaModulos)
        //----console.log("data lista modulos[0]: " +listaModulos[0])
        //----console.log("largo de listamodulo: " + listaModulos.length);

        setLoading(true);
        ////----console.log("LISTA MODULO DESP SET " + listaModulos);
        ////----console.log("LISTA MODULO DESP SET en [0]" + listaModulos[0]);
      })
      .catch((error) => {
        alert(error.response.data.message);
        setListaModulos([]);
        setLoading(true);
        //----console.log(error);
      });
  };

  ////----console.log(listaModulos[0]);
  //console.log("listaModulos");
  //console.log(listaModulos);

  if (props.largoConsulta === 0) {
    for (let index = 0; index < modulos.length; index++) {
      list[index] = new Array(weekdays.length);

      for (let index2 = 1; index2 <= weekdays.length; index2++) {
        list[index][index2 - 1] = null;
        let i = 0;
        let j = 0;
        let z = 0;
        let k = 0;

        let nombreAsig = '';
        let nombreAsigAux = '';
        listaModulos.forEach((element) => {
          //si el dia y modulo
          if (element[9] === index2 && element[10] === modulos[index]) {

            nombreAsig = element[11];
            //dataAux.push(list[index][index2-1]);

            if (nombreAsig === nombreAsigAux) {
              j++;
            } else if (i > 0) {
              z++;
            }
            // este seria varias secciones
            if (j === i - 1) {
              k++;
            }
            ////----console.log(nombreAsig +" == " + nombreAsigAux)
            nombreAsigAux = nombreAsig;
            i++;

            list[index][index2 - 1] = [element[6], element[11].substring(0,20), element[12], element[13], element[9], 0, 0, element[10], element[11], i, j, k, z, element[19]];
            ////----console.log("INFO LIST " + list[index][index2 - 1])
            ////----console.log("INFO LIST CON [] [] " + list)
            ////----console.log(dataAux)
            ////----console.log("valor de i en el dia " + index2 + " modulo " + 2 * index + " valor de i: " + list[index][index2-1][4] + " VALOR DE J: " + list[index][index2-1][5])
          }
        });
      //console.log("valor de i en el dia " + index2 + " modulo " + index + " valor de i: " + i + " VALOR DE J: " + j + " valor de K: " + k + " VALOR DE Z: " + z);
      }
    }
    //console.log("INFO LIST ");
    //console.log(list);
  //console.log("List push");
  //console.log(list);
  } else if (props.largoConsulta > 0) {
    //----console.log("estoy en el else con un largoconsulta: " + props.largoConsulta)
    for (let index0 = 0; index0 < listaModulos.length; index0++) {
      //list2[index0] = new Array(listaModulos.length);
      for (let index = 0; index < modulos.length; index++) {
        list2[index] = new Array(weekdays.length);
        list[index] = new Array(weekdays.length);

        for (let index2 = 1; index2 <= weekdays.length; index2++) {
          list2[index][index2 - 1] = null;
          let i = 0;
          let j = 0;
          let z = 0;
          let k = 0;
          let nombreAsig = '';
          let nombreAsigAux = '';
          listaModulos[index0].forEach((element) => {
            ////----console.log("ANTES DEL IF")
            //----console.log( "dia y modulo" +element[8] +" " + element[9]);
            if (element[8] === index2 && element[9] === modulos[index]) {
              // //----console.log("DESPUES")
              list2[index][index2 - 1] = [element[6], element[11], element[12], element[13], i, k, z, element[10], element[9]];

              /* //----console.log("INFO LIST" + list)   
            //----console.log("INFO LIST CON [] [] " + list[index][index2])    */
              nombreAsig = element[10];
              //dataAux.push(list[index][index2-1]);

              if (nombreAsig === nombreAsigAux) {
                j++;
              }

              if (i > 1 && j > 1) {
                z++;
              }
              // este seria varias secciones
              if (j === i - 1) {
                k++;
              }
              ////----console.log(nombreAsig +" == " + nombreAsigAux)
              nombreAsigAux = nombreAsig;
              i++;
              list.push(list2[index][index2 - 1]);

              ////----console.log(dataAux)
              ////----console.log("valor de i en el dia " + index2 + " modulo " + 2 * index + " valor de i: " + list[index][index2-1][4] + " VALOR DE J: " + list[index][index2-1][5])
            }
          });
        }
      }
    }
  }

  const showHorario = () => {
    if (props.largoConsulta === 0) {
      getHorario(props.codigoSala);
    } else {
      getHorario2(props.codigoSala, props.hasta);
    }
    setLoading(false);
  };

  const hideHorario = () => {
    toggleShowHorario();
  };

  const abrirVariasSecc = (dia, modulo) => {

    setDia(weekdays[dia - 1]);
    setNumDia(dia);
    setModulo(modulo);
    setVariasSecc(true);
    //setLoadingVariasSecc(true);
  };

  async function downloadPDF() {
    try{
      const response = await apiInstance.get(serverUrl + '/getPdfBuscador', {
        params: { periodo: props.periodo, cod_sala: props.codigoSala, hasta: props.codigoSala },
        responseType: 'blob',
      });
      const file = new Blob([response.data],{type: 'application/pdf'});
      const url = URL.createObjectURL(file);
      window.open(url);
     } catch (error){
      console.error('Error al descargar PDF', error);
     }
  }
  if (props.largoConsulta === 0) {
/*  console.log(list);
    console.log(props);*/
    return (
      <Modal
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
        centered
        show={show}
        onShow={showHorario}
        onHide={hideHorario}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter' className='contenedorTitulo'>
            {<img alt='' className='imagenUsach' src={imagenUsach}></img>}
            {
              <h2 className='textoInformacionSalas'>
                UNIVERSIDAD DE SANTIAGO DE CHILE <br></br> VICERRECTORÍA ACADÉMICA<br></br>UNIDAD DE REGISTRO ACADÉMICO Y CURRICULAR
              </h2>
            }
            <div className='elementosSala'>
              <h4>PERIODO: {props.periodo}</h4>
              <h4>DESCRIPCIÓN: {props.descripcion}</h4>
              <h4>CÓDIGO: {props.codigo}</h4>
              <h4>CAPACIDAD: {props.capacidad} </h4>
              <h4>RESPONSABLE: {props.responsable} </h4>
              <h4>TIPO DE SALA: {props.tipoSala} </h4>
              <h5>
                M2: {props.m2} LARGO: {props.largo} ANCHO: {props.ancho}{' '}
              </h5>
            </div>
            <Button className='boton' onClick={downloadPDF}>
              DescargarPDF
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='fondoHorario'>
          <Container>
            <MostrarVariasSecc
              show={showVariasSecc}
              toggleShow={toggleShow}
              sala={props.sala}
              dia={dia}
              modulo={modulo}
              periodo={props.periodo}
              numeroDia={numeroDia}
              //codigoSala = {props.codigoSala}
            />
            {isLoading ? (
              <Table responsive bordered id='fondo'>
                <thead>
                  <tr id='dias'>
                    <th> </th>
                    {Array.from({ length: weekdays.length }).map((_, index) => (
                      <th id='colDia' scope='col' key={weekdays[index]}>
                        {weekdays[index]}{' '}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='body'>
                  {Array.from({ length: modulos.length }).map((_, index1) => (
                    <tr key={index1}>
                      <>
                        <th scope='row' key={modulos[index1]} className='cModulos'>
                          {modulos[index1]}
                          {'\n'}
                          {props.periodo.split("-")[0] >= 2022 ? hora[index1] : hora_antigua[index1]}
                        </th>
                        {Array.from({ length: weekdays.length }).map((_, index2) =>
                          list[index1][index2] != null ? (
                            list[index1][index2][12] < 1 ? (
                              list[index1][index2][11] < 1 ? (
                                list[index1][index2][10] < 1 ? (
                                  <td id='infoNormal' height={150} key={modulos[index1] + weekdays[index2]}>
                                    <a onClick={() => abrirVariasSecc(list[index1][index2][4], list[index1][index2][7])}>
                                      <div className='infoHorario'>
                                        <strong>CODIGO:</strong> {list[index1][index2][0]} <br></br>
                                        <strong>ASIGNATURA:</strong> {list[index1][index2][1]} <br></br>
                                        <strong>SECCIÓN:</strong> {list[index1][index2][2]} <br></br>
                                        <strong>ALUMNOS:</strong> {list[index1][index2][13]}
                                      </div>
                                    </a>
                                  </td>
                                ) : (
                                  <td id='variasSecc' height={150} key={modulos[index1] + weekdays[index2]}>
                                    <a onClick={() => abrirVariasSecc(list[index1][index2][4], list[index1][index2][7])}>
                                      <strong>CODIGO:</strong> {list[index1][index2][0]} <br></br>
                                      <strong>ASIGNATURA:</strong> {list[index1][index2][1]} <br></br>
                                      <strong>VARIAS SECCIONES</strong>
                                    </a>
                                    {isLoadingVariasSecc ? (
                                      <Col>
                                        <Row>
                                          <Col className='loading2'>
                                            <Button variant='primary' disabled>
                                              <span>Cargando </span>
                                              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                    ) : (
                                      <div></div>
                                    )}
                                  </td>
                                )
                              ) : (
                                <td id='asigMult' height={150} key={modulos[index1] + weekdays[index2]}>
                                  {/* asignacion multiple con varias secciones */}
                                  <a onClick={() => abrirVariasSecc(list[index1][index2][4], list[index1][index2][7])}>
                                    <strong>ASIGNACION MULTIPLE</strong>
                                  </a>
                                </td>
                              )
                            ) : (
                              <td id='asigMult' height={150} key={modulos[index1] + weekdays[index2]}>
                                <a onClick={() => abrirVariasSecc(list[index1][index2][4], list[index1][index2][7])}>
                                  <strong>ASIGNACION MULTIPLE</strong><br></br>
                                </a>
                              </td>
                            )
                          ) : (
                            <td height={150} key={modulos[index1] + weekdays[index2]}></td>
                          )
                        )}
                      </>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Container className='loading'>
                <Row>
                  <Col></Col>
                  <Col>
                    <Button variant='primary' disabled>
                      <span>Cargando... </span>
                      <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                    </Button>
                  </Col>
                  <Col></Col>
                </Row>
              </Container>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideHorario}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
        centered
        show={show}
        onShow={showHorario}
        onHide={hideHorario}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter' className='contenedorTitulo'></Modal.Title>
        </Modal.Header>
        <Table responsive bordered id='fondo'></Table>:
        <Modal.Footer>
          <Button onClick={hideHorario}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default HorarioBusSalas;
