import React, { useEffect, useState } from 'react';
import Dash from '../views/Dash.js';
import UnidadAcademica from '../views/UnidadAcademica.js';
import Usuarios from '../views/Usuarios.js';
import Avisos from '../views/Avisos.js';
import MantenedorSalas from '../views/MantenedorSalas.js';
import Sectores from '../views/Sectores.js';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PlanEstudio from '../views/PlanEstudio.js';
import BuscadorSala from '../views/BuscadorSala.js';
import Perfil from '../views/Perfil.js';
import InformeSala from '../views/InformeSala';
import InformeUnidad from '../views/InformeUnidad.js';
import InformeEstadistico from '../views/InformeEstadistico.js';
import Solicitudes from '../views/Solicitudes.js';
import SolicitudesAdmin from '../views/SolicitudesAdmin.js';
import VerSolicitud from '../views/VerSolicitud.js';
import CrearSolicitud from '../views/CrearSolicitud.js';
import Roles from '../views/Roles.js';
import PrestamoDiario from '../views/PrestamoDiario.js';
import MantenedorSectores from '../views/MantenedorSectores';
import MantenedorParametro from '../views/MantenedorParametro.js';

import axios from 'axios';
import apiInstance from '../api/ApiInstance';
import Cookies from 'universal-cookie';

const conectado = new Cookies();
const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});

//var usuario = conectado.get('nombre');
var id_usuario = conectado.get('id');

function Router() {
  const [funcionalidades, setFuncionalidades] = useState([]);

  const getRolByUsuario = async () => {
    ////----console.log("id_usuario: "+id_usuario);
    await apiInstance
      .get(serverUrl + '/getFuncionalidadesByUsuario', { params: { id_usuario: id_usuario } })
      .then((response) => {
        let lista = [];
        for (let i = 0; i < response.data.length; i++) {
          lista.push(response.data[i][0]);
        }
        setFuncionalidades(lista);
        //setLoadingFunc(true);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  useEffect(() => {
    getRolByUsuario();
  }, []);


  //<Route path='/BuscadorSala' element={<BuscadorSala />} />
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dash />} />
        {funcionalidades.includes(1) && <Route path='/unidad-academica' element={<UnidadAcademica />} />}
        {funcionalidades.includes(2) ? <Route path='/planEstudio' element={<PlanEstudio />} /> : <></>}
        {funcionalidades.includes(15) ? <Route path='/prestamoDiario' element={<PrestamoDiario />} /> : <></>}
        {funcionalidades.includes(19) ? <Route path='/BuscadorSala' element={<BuscadorSala />} /> : <></>}
        {funcionalidades.includes(7) ? <Route path='/usuarios' element={<Usuarios />} /> : <></>}
        {funcionalidades.includes(4) ? <Route path='/informe-salas' element={<InformeSala />} /> : <></>}
        {funcionalidades.includes(5) ? <Route path='/informe-unidades' element={<InformeUnidad />} /> : <></>}
        {funcionalidades.includes(6) ? <Route path='/informe-estadistico' element={<InformeEstadistico />} /> : <></>}
        {funcionalidades.includes(9) ? <Route path='/avisos' element={<Avisos />} /> : <></>}
        {funcionalidades.includes(8) ? <Route path='/salas' element={<MantenedorSalas />} /> : <></>}
        <Route path='/Sectores' element={<Sectores />} />
        {funcionalidades.includes(10) ? <Route path='/roles' element={<Roles />} /> : <></>}
        <Route path='/Perfil' element={<Perfil />} />
        {funcionalidades.includes(3) ? <Route path='/solicitudes' element={<Solicitudes />} /> : <></>}
        {funcionalidades.includes(14) ? <Route path='/responderSolicitudes' element={<SolicitudesAdmin />} /> : <></>}
        {funcionalidades.includes(3) ? <Route path='/verSolicitud' element={<VerSolicitud />} /> : <></>}
        {funcionalidades.includes(3) ? <Route path='/crearSolicitud' element={<CrearSolicitud />} /> : <></>}
        {funcionalidades.includes(17) ? <Route path='/mantenedorSectores' element={<MantenedorSectores />} /> : <></>}
        {funcionalidades.includes(18) ? <Route path='/mantenedorParametro' element={<MantenedorParametro />} /> : <></>}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
