import React from 'react'
import Dashboard from '../components/Dashboard';
import 'bootstrap/dist/css/bootstrap.css';


function Dash() {
  return (  
      <div className="App">
          <Dashboard/>
      </div>
  );
}

export default Dash;