import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap';
import '../c_styles/UnidadAcademica.css';
import swal from 'sweetalert';
import axios from 'axios';
import apiInstance from '../api/ApiInstance';
const serverUrl = process.env.REACT_APP_SERVER_API;
const ax = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
});

function ModalCrearParametro(props) {
  const [nuevoNombreParametro, setnuevoNombreParametro] = useState('');
  const [nuevoValorParametro, setNuevoValorParametro] = useState('');
  const [nuevoDescripcionParametro, setNuevoDescripcionParametro] = useState('');

  const cerrarModalCrearParametro = () => {
    setnuevoNombreParametro('');
    props.onHide();
  };

  const crearParametro = async () => {
    await ax({
      method: 'post',
      url: serverUrl + '/createParametro',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        nombre: nuevoNombreParametro,
        valor: nuevoValorParametro,
        descripcion: nuevoDescripcionParametro,
      },
    })
      .then((response) => {
        //----console.log(response.data);
        window.location.reload(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const confirmarCrearParametro = () => {
    swal({
      title: 'Crear Parámetro',
      text: '¿Está seguro que desea crear este Parámetro?',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
    }).then((respuesta) => {
      if (respuesta) {
        swal({ text: 'El Parámetro ha sido creado exitosamente!', icon: 'success', timer: '4000' });
        crearParametro();
      }
    });
  };

  return (
    <Modal {...props} centered>
      <Modal.Header>
        <Modal.Title>Crear Nuevo Parametro</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <Form.Group className='mb-3'>
          <Form.Label>Nombre de Parámetro</Form.Label>
          <Form.Control
            onChange={(event) => {
              setnuevoNombreParametro(event.target.value);
            }}
            type='text'
          />
          <Form.Label>Valor del Parámetro</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoValorParametro(event.target.value);
            }}
            type='text'
          />
          <Form.Label>Descripción del Parámetro</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoDescripcionParametro(event.target.value);
            }}
            type='text'
          />
        </Form.Group>
        <Form.Group className='mb-3'></Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={confirmarCrearParametro}>Crear Parámetro</Button>
        <Button variant='outline-danger' onClick={cerrarModalCrearParametro}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalModificarParametro(props) {
  const [nuevoNombreParametro, setnuevoNombreParametro] = useState('');
  const [nuevoValorParametro, setNuevoValorParametro] = useState('');
  const [nuevoDescripcionParametro, setNuevoDescripcionParametro] = useState('');

  useEffect(() => {
    setnuevoNombreParametro(props.parametro[0]);
    setNuevoValorParametro(props.parametro[1]);
    setNuevoDescripcionParametro(props.parametro[2]);
  }, [props]);

  const modificarParametro = async (props) => {
    await ax({
      method: 'put',
      url: serverUrl + '/updateParametro',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        nombre: props.parametro[0],
        valor: nuevoValorParametro,
        descripcion: nuevoDescripcionParametro,
      },
    })
      .then((response) => {
        //----console.log(response.data);
        window.location.reload(false);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const confirmarModificarParametro = (props) => {
    swal({
      title: 'Modificar parámetro',
      text: '¿Está seguro que desea modificar este parámetro?',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
    }).then((respuesta) => {
      if (respuesta) {
        swal({ text: 'El parámetro ha sido modificado exitosamente!', icon: 'success', timer: '4000' });
        modificarParametro(props);
      }
    });
  };

  return (
    <Modal {...props} centered>
      <Modal.Header>
        <Modal.Title>Modificar Parámetro</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <Form.Group className='mb-3'>
          <Form.Label>Nombre Parámetro</Form.Label>
          <Form.Control
            onChange={(event) => {
              setnuevoNombreParametro(event.target.value);
            }}
            defaultValue={props.parametro[0]}
            type='text'
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Valor del Parámetro</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoValorParametro(event.target.value);
            }}
            defaultValue={props.parametro[1]}
            type='text'
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Descripción del Parámetro</Form.Label>
          <Form.Control
            onChange={(event) => {
              setNuevoDescripcionParametro(event.target.value);
            }}
            defaultValue={props.parametro[2]}
            type='text'
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            confirmarModificarParametro(props);
          }}
        >
          Modificar Parámetro
        </Button>
        <Button variant='outline-danger' onClick={props.onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MantenedorParametro() {
  const [cargando1, setCargando1] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [modalCrearParametro, setModalCrearParametro] = useState(false);
  const [modalModificarParametro, setModalModificarParametro] = useState(false);
  const [funcionalidades, setFuncionalidades] = useState([]);
  const [parametro, setParametros] = useState([]);
  const [parametroActual, setParametroActual] = useState(['', '', []]);

  const getSectores = async () => {
    apiInstance
      .get(serverUrl + '/getParametro')
      .then((response) => {
        setParametros(response.data);
        setCargando2(true);
        setCargando1(true);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const modificarParametro = (parametro) => {
    setParametroActual(parametro);
    ////----console.log(parametroActual);
    setModalModificarParametro(true);
  };

  const eliminarParametro = async (parametro) => {
    await apiInstance
      .delete(serverUrl + '/deleteParametro', { params: { id: parametro[0] } })
      .then((response) => {
        window.location.reload(false);
        ////----console.log(response.data);
      })
      .catch((error) => {
        alert(error.response.data.message);
        //**//----console.log(error);
      });
  };

  const confirmarEliminarParametro = (parametro) => {
    swal({
      title: 'Eliminar parámetro',
      text: '¿Está seguro que desea eliminar ese parámetro?',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
    }).then((respuesta) => {
      if (respuesta) {
        swal({ text: 'El parámetro ha sido eliminado exitosamente!', icon: 'success', timer: '5000' });
        eliminarParametro(parametro);
      }
    });
  };

  useEffect(() => {
    getSectores();
  }, []);

  if (cargando1 && cargando2) {
    return (
      <Container className='fondoUnidadAcademica' fluid>
        <Container className='UnidadAcademica' fluid>
          <Row className='mb-3'>
            <Col md={10}>
              <h3>Gestionar Parámetros</h3>
            </Col>
            <Col md={2}>
              <Button variant='primary' onClick={() => setModalCrearParametro(true)}>
                Crear nuevo parámetro
              </Button>
            </Col>
          </Row>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>Nombre Parámetro</th>
                <th>Valor Parámetro</th>
                <th>Descripción Parámetro</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {parametro.map((parametro) => {
                return (
                  <>
                    <tr key={parametro.id}>
                      <td className='p-2'>{parametro[0]}</td>
                      <td className='p-2'>{parametro[1]}</td>
                      <td className='p-2'>{parametro[2]}</td>
                      <td className='text-center p-2'>
                        <Button
                          size='sm'
                          variant='primary'
                          onClick={() => {
                            modificarParametro(parametro);
                          }}
                        >
                          Modificar
                        </Button>{' '}
                        <Button
                          size='sm'
                          variant='danger'
                          onClick={() => {
                            confirmarEliminarParametro(parametro);
                          }}
                        >
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          <ModalCrearParametro show={modalCrearParametro} onHide={() => setModalCrearParametro(false)} />
          <ModalModificarParametro parametro={parametroActual} show={modalModificarParametro} onHide={() => setModalModificarParametro(false)} />
        </Container>
      </Container>
    );
  } else {
    return (
      <Container className='fondoUnidadAcademica' fluid>
        <Container className='UnidadAcademica' fluid>
          <Row>
            <Col></Col>
            <Col className='loading'>
              <Button variant='primary' disabled>
                <span>Cargando sectores... </span>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default MantenedorParametro;
